import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`KOPE uses Auth0 for authorization.`}</p>
    </PageDescription>
    <p>{`all our APIs are protected by auth tokens and we currently have 2 ways to get them`}</p>
    <p>{`user tokens - through the web app (the login flow), requires user interaction but after you get one, you can sniff it out in dev tools if you want to use it with the APIs`}</p>
    <p>{`machine tokens - we give you a client id and client secret (we manually provision in Auth0) and you can exchange them for a machine token to call our APIs. Only some endpoints accept these, not all of them`}</p>
    <p>{`for the auth portion, out authentication uses standard OAuth 2.0 protocol btw`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      